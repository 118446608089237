import React, { useRef, useEffect, useState, Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import "@splidejs/react-splide/css";
import "../css/app-screen.css";
import { Stats } from "@react-three/drei";
import * as THREE from "three";
import { View } from "@react-three/drei";
import Camera from "../scene-components/Camera";
import { Perf } from "r3f-perf";
import { useControls } from "leva";
import {
  SoftShadows,
  Float,
  CameraControls,
  Sky,
  PerformanceMonitor,
} from "@react-three/drei";
import { Icon } from "@iconify/react";
import { Lights } from "../scene-components/Lights";
import FinalScene from "../scene-components/FinalScene";
import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import ShoesSideBar from "../components/ShoesSideBar";
import HairsSideBar from "../components/HairsSideBar";
import CompareScene from "../scene-components/CompareScene";
import LightsSideBar from "../components/LightsSideBar";
import MobileMenu from "../components/MobileMenu";
import DesktopMenu from "../components/DesktopMenu";
import GenrateAvatarPopUp from "../components/GenrateAvatarPopUp";
import Loader from "../components/Loader";

const AppScreen = () => {
  const cameraControlsRef = useRef();
  const container = useRef();
  const [bad, set] = useState(false);
  const { enabled, samples, size, focus } = useControls("Soft Shadows", {
   
    enabled: true,
    size: { value: 35, min: 0, max: 100, step: 0.1 },
    focus: { value: 0.5, min: 0, max: 2, step: 0.1 },
    samples: { value: 1, min: 1, max: 64, step: 1 },
  });
  const { avatar } = useSelector((state) => state.avatarModelDetails);

  const [fabricData, setFabricData] = useState();
  const [fullScreen, setFullScreen] = useState(false);

  const [activeCanvas, setActiveCanvas] = useState(0);

  const [activeFabricTab, setActiveFabricTab] = useState("Styles");

  const [styleDetails, setStyleDetails] = useState("DB_CLOTH");
  const [compareStyleDetails, setCompareStyleDetails] = useState("DB_CLOTH");

  const [suitDetails, setSuitDetails] = useState();
  const [trouserDetails, setTrouserDetails] = useState();
  const [shirtDetails, setShirtDetails] = useState();

  const [compareSuitDetails, setCompareSuitDetails] = useState();
  const [compareTrouserDetails, setCompareTrouserDetails] = useState();
  const [compareShirtDetails, setCompareShirtDetails] = useState();

  const [shoesColor, setShoesColor] = useState(0);
  const [showShoesPanel, setShowShoesPanel] = useState(false);

  const [lightColor, setLightColor] = useState("#FFFFFF");
  const [showLightsPanel, setShowLightsPanel] = useState(false);

  const [currentHairStyle, setCurrentHairStyle] = useState(0);
  const [showHairStylePanel, setShowHairStylePanel] = useState(false);

  const [showCompare, setShowCompare] = useState(false);

  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    const getAllFabrics = async () => {
      const { data } = await axios.get(
        "https://backend.ingnious.ai/api/fabric/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk4MDY0MmZiLWE3OTEtNGZjNS05NjE3LWUyNTA0Zjk1ZWY1MiIsImlhdCI6MTczMTQ4MjMyMywiZXhwIjoxNzYyNTg2MzIzfQ.3qSjW-dJgACm4SC4_QmO3L5g9_OdajXqkl7ELmHoOHA",
          },
        }
      );
      setFabricData(data);
    };

    getAllFabrics();
  }, []);

  const { source: sourceModel } = useSelector((state) => state.sourceModel);

  const { target: targetModel } = useSelector((state) => state.targetModel);
  const { product } = useSelector((state) => state.productModel);

  return (
    <>
      {<Loader show={!sourceModel && !targetModel && !product && true} />}
      <div className=" ">
        <div className="container-fluid main-window-container  ">
          <div className="row   h-100">
            <div
              ref={container}
              className={` col-lg-9 col-12 h-100 d-flex  overflow-hidden p-0 position-relative canvas-div  `}
            >
              <div
                className={"w-100 position-relative"}
                style={{ height: "100%", zIndex: "9" }}
              >
                <div
                  className="   d-none  p-3  position-absolute"
                  style={{
                    bottom: "0%",
                    zIndex: "10",
                    fontSize: "0.7em",
                    width: "40%",
                    color: "#CEC5C5",
                  }}
                >
                  <div className="d-flex mt-3">
                    <p className="col-4 m-0 me-4">Jacket:</p>{" "}
                    <p className="m-0"> {suitDetails?.skuCode} </p>
                  </div>
                  <div className="d-flex mt-2">
                    <p className="col-4 m-0 me-4">Shirt:</p>{" "}
                    <p className="m-0"> {shirtDetails?.skuCode} </p>
                  </div>
                  <div className="d-flex mt-2">
                    <p className="col-4 m-0 me-4">Trousers:</p>{" "}
                    <p className="m-0"> {trouserDetails?.skuCode} </p>
                  </div>
                </div>

                <div className="d-flex" id="views">
                  {/* Left side with first View */}
                  <div className="bg-danger" style={{ flex: 1 }}>
                    <div className="position-relative">
                      <div
                        className=" rounded-3 mt-2 bg-primary"
                        style={{
                          height: "5px",
                          position: "absolute",
                          top: "15%",
                          left: "50%",
                          translate: "-50% 0%",
                          width:
                            showCompare && activeCanvas === 0 ? "20%" : "0%",
                          zIndex: "10",
                          transition: "0.3s linear",
                        }}
                      ></div>
                    </div>

                    <View
                      style={{
                        position: "absolute",
                        width: showCompare ? "50%" : "100%",
                        height: "100%",
                      }}
                      onClick={() => setActiveCanvas(0)}
                    >
                      <Lights lightColor={lightColor} />

                      <Suspense fallback={null}>
                        <FinalScene
                          key="final"
                          avatarTexture={`https://raymond101.s3.ap-south-1.amazonaws.com/output_uploads/${avatar.modelId}/model.jpeg`}
                          suitDetails={suitDetails}
                          shirtDetails={shirtDetails}
                          trouserDetails={trouserDetails}
                          cameraControlsRef={cameraControlsRef}
                          shoesColor={shoesColor}
                          activeFabricTab={activeFabricTab}
                          currentHairStyle={currentHairStyle}
                          styleDetails={styleDetails}
                          hairStylesList={[
                            `https://raymond101.s3.ap-south-1.amazonaws.com/output_uploads/${avatar.modelId}/Hair_Classic_Short.glb`,
                            `https://raymond101.s3.ap-south-1.amazonaws.com/output_uploads/${avatar.modelId}/Hair_Curly_Fade.glb`,
                            `https://raymond101.s3.ap-south-1.amazonaws.com/output_uploads/${avatar.modelId}/Hair_Receding.glb `,
                          ]}
                          weight={80}
                        />
                      </Suspense>
                      {enabled && (
                        <SoftShadows
                          size={size}
                          focus={focus}
                          samples={bad ? Math.min(6, samples) : samples}
                        />
                      )}
                    </View>
                  </div>

                  {/* Right side with second View (conditionally rendered) */}
                  <div className="" style={{ flex: 1 }}>
                    <div className="position-relative">
                      <div
                        className=" rounded-3 mt-2 bg-warning"
                        style={{
                          height: "5px",
                          position: "absolute",
                          top: "15%",
                          left: "50%",

                          translate: "-50% 0%",
                          width:
                            showCompare && activeCanvas === 1 ? "20%" : "0%",
                          zIndex: "10",
                          transition: "0.3s linear",
                        }}
                      ></div>
                    </div>

                    {showCompare && (
                      <View
                        style={{
                          position: "absolute",
                          width: "50%", // Take full width within flex container
                          height: "100%", // Full height
                        }}
                        onClick={() => setActiveCanvas(1)}
                      >
                        <Lights lightColor={lightColor} />

                        <Suspense fallback={null}>
                          <CompareScene
                            key="compare"
                            suitDetails={compareSuitDetails}
                            shirtDetails={compareShirtDetails}
                            trouserDetails={compareTrouserDetails}
                            styleDetails={styleDetails}
                            compareStyleDetails={compareStyleDetails}
                          />
                        </Suspense>
                      </View>
                    )}
                  </div>
                </div>

                {/* Canvas below the two Views */}
                <Canvas
                  id="plugin-canvas"
                  shadows={{ type: THREE.PCFSoftShadowMap }}
                  eventSource={container}
                  style={{ minHeight: "100%", zIndex: "9" }}
                  camera={{ fov: 21, position: [0, 0, 3.4] }}
                  gl={{
                    antialias: true,
                    toneMapping: THREE.ACESFilmicToneMapping,
                    outputColorSpace: THREE.SRGBColorSpace, // Updated for newer three.js versions
                    powerPreference: "high-performance",
                    alpha: false,

                    stencil: false,
                  }}
                >
                  <Perf position="bottom-left" />
                  <PerformanceMonitor onDecline={() => set(true)} />

                  <Camera cameraControlsRef={cameraControlsRef} />
                  <View.Port />
                </Canvas>
              </div>

              {/* MENU */}
              <div
                className={`model-menu position-absolute  d-lg-block text-light  p-2 rounded-3  `}
                style={{
                  zIndex: "10",
                  top: "5%",
                  left: showMenu ? "3%" : "-20%",
                  cursor: "pointer",
                  background: "#342F2F",
                  transition: "0.5s ease-in-out",
                }}
              >
                <div
                  className="p-1 mb-2  text-center mt-2"
                  onClick={() => {
                    setShowLightsPanel(true);
                    setShowMenu(false);
                  }}
                >
                  <Icon icon="mdi:lightbulb-on" width={25} />
                </div>
                <hr className="m-0 bg-light text-light " />

                {/*  Shoes Menu */}
                <div
                  className={`p-1 pt-2 text-center pb-2 mb-2 mt-1 ${
                    showCompare && "grey-text"
                  } `}
                  onClick={() => {
                    if (!showCompare) {
                      setShowShoesPanel(true);
                      setShowMenu(false);
                      if (cameraControlsRef.current) {
                        // Set the initial camera position and target
                        cameraControlsRef.current.setLookAt(
                          0,
                          -0.8,
                          1.5, // Camera position (x, y, z)
                          0,
                          -3,
                          0, // Target position (x, y, z)
                          true // Enable smooth transition
                        );
                      }
                    }
                  }}
                >
                  <Icon
                    icon="fluent-emoji-high-contrast:mans-shoe"
                    width={27}
                  />
                </div>
                <hr className="m-0 bg-light text-light " />
                <div
                  className={`p-1 pt-2 text-center pb-2 mb-2 mt-2  ${
                    showCompare && "grey-text"
                  }`}
                  data-toggle="tooltip"
                  data-placement="right"
                  title="HairStyles"
                  onClick={() => {
                    if (!showCompare) {
                      setShowHairStylePanel(true);
                      setShowMenu(false);
                      if (cameraControlsRef.current) {
                        // Set the initial camera position and target
                        cameraControlsRef.current.setLookAt(
                          0.7,
                          -1.5,
                          1.5, // Camera position (x, y, z)
                          0,
                          -1.6,
                          0,
                          true // Enable smooth transition
                        );
                      }
                    }
                  }}
                >
                  <Icon icon="icon-park-outline:comb" width={25} />
                </div>
                <hr className="m-0 bg-light text-light " />
                <div
                  className="p-1 pt-2 text-center pb-2 mb-0 mt-1"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Compare"
                  onClick={() => {
                    setShowCompare(!showCompare);
                  }}
                >
                  <Icon icon="ic:round-compare-arrows" width={30} />
                </div>
              </div>

              <LightsSideBar
                fullScreen={fullScreen}
                lightColor={lightColor}
                setLightColor={setLightColor}
                showLightsPanel={showLightsPanel}
                setShowLightsPanel={setShowLightsPanel}
                setShowMenu={setShowMenu}
                cameraControlsRef={cameraControlsRef}
              />

              <ShoesSideBar
                fullScreen={fullScreen}
                shoesColor={shoesColor}
                setShoesColor={setShoesColor}
                showShoesPanel={showShoesPanel}
                setShowShoesPanel={setShowShoesPanel}
                setShowMenu={setShowMenu}
                cameraControlsRef={cameraControlsRef}
              />
              <HairsSideBar
                fullScreen={fullScreen}
                currentHairStyle={currentHairStyle}
                setCurrentHairStyle={setCurrentHairStyle}
                showHairStylePanel={showHairStylePanel}
                setShowHairStylePanel={setShowHairStylePanel}
                setShowMenu={setShowMenu}
                cameraControlsRef={cameraControlsRef}
              />

              {/*  Close Compare Button */}
              {showCompare && (
                <div
                  className="position-absolute"
                  style={{
                    top: "4%",
                    right: "2%",
                    zIndex: "99",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowCompare(!showCompare);
                  }}
                >
                  <Icon
                    icon="ic:round-close"
                    width={30}
                    className="text-light"
                  />
                </div>
              )}

              {/* full screen button */}
              <div
                className="position-absolute d-none d-lg-block"
                style={{
                  bottom: "4%",
                  right: "3%",
                  zIndex: "99",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFullScreen(!fullScreen);
                  let elem = document.documentElement;
                  if (!fullScreen) {
                    if (elem.requestFullscreen) {
                      elem.requestFullscreen();
                    } else if (elem.webkitRequestFullscreen) {
                      /* Safari */
                      elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                      /* IE11 */
                      elem.msRequestFullscreen();
                    }
                  } else {
                    if (document.exitFullscreen) {
                      document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                      /* Safari */
                      document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                      /* IE11 */
                      document.msExitFullscreen();
                    }
                  }
                }}
              >
                {fullScreen ? (
                  <Icon
                    icon="material-symbols:fullscreen-exit-rounded"
                    width={30}
                    className="text-light"
                  />
                ) : (
                  <Icon
                    icon="mingcute:fullscreen-line"
                    width={30}
                    className="text-light"
                  />
                )}
              </div>

              {/* Generate Avatar */}
              <div
                className={`model-menu position-absolute ${
                  showCompare ? "d-none" : "d-lg-block"
                }   text-light  p-2 rounded-3  `}
                style={{
                  zIndex: "10",
                  top: "5%",
                  right: !showMenu ? "-20%" : "3%",
                  cursor: "pointer",
                  background: "#342F2F",
                  transition: "0.5s ease-in-out",
                }}
              >
                <button
                  className="p-1 btn text-light ps-2  text-center "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <Icon icon="fa-solid:user-cog" width={22} />
                </button>
              </div>
              <GenrateAvatarPopUp modelId={avatar.modelId} />
            </div>

            <div
              className={` h-100 pt-lg-0 d-none d-lg-block position-relative side-panel p-2 overflow-auto col-3  `}
            >
              <DesktopMenu
                setActiveFabricTab={setActiveFabricTab}
                activeFabricTab={activeFabricTab}
                fabricData={fabricData}
                styleDetails={styleDetails}
                activeCanvas={activeCanvas}
                setCompareStyleDetails={setCompareStyleDetails}
                setStyleDetails={setStyleDetails}
                showCompare={showCompare}
                compareSuitDetails={compareSuitDetails}
                suitDetails={suitDetails}
                setSuitDetails={setSuitDetails}
                setCompareSuitDetails={setCompareSuitDetails}
                shirtDetails={shirtDetails}
                setShirtDetails={setShirtDetails}
                setCompareShirtDetails={setCompareShirtDetails}
                trouserDetails={trouserDetails}
                compareTrouserDetails={compareTrouserDetails}
                setTrouserDetails={setTrouserDetails}
                setCompareTrouserDetails={setCompareTrouserDetails}
                compareStyleDetails={compareStyleDetails}
                compareShirtDetails={compareShirtDetails}
              />
            </div>
          </div>
        </div>

        <MobileMenu
          fabricData={fabricData}
          styleDetails={styleDetails}
          activeCanvas={activeCanvas}
          setCompareStyleDetails={setCompareStyleDetails}
          setStyleDetails={setStyleDetails}
          showCompare={showCompare}
          compareSuitDetails={compareSuitDetails}
          suitDetails={suitDetails}
          setSuitDetails={setSuitDetails}
          setCompareSuitDetails={setCompareSuitDetails}
          shirtDetails={shirtDetails}
          setShirtDetails={setShirtDetails}
          setCompareShirtDetails={setCompareShirtDetails}
          trouserDetails={trouserDetails}
          compareTrouserDetails={compareTrouserDetails}
          setTrouserDetails={setTrouserDetails}
          setCompareTrouserDetails={setCompareTrouserDetails}
          compareStyleDetails={compareStyleDetails}
        />
      </div>
    </>
  );
};

export default AppScreen;
