import React from "react";
import FabricsCard from "./FabricsCard";

const DesktopMenu = ({
  activeFabricTab,
  styleDetails,
  activeCanvas,
  setCompareStyleDetails,
  setStyleDetails,
  fabricData,
  compareSuitDetails,
  showCompare,
  suitDetails,
  setCompareSuitDetails,
  setSuitDetails,
  shirtDetails,
  setShirtDetails,
  setCompareShirtDetails,
  trouserDetails,
  compareTrouserDetails,
  setTrouserDetails,
  setCompareTrouserDetails,
  compareStyleDetails,
  setActiveFabricTab,
  compareShirtDetails
}) => {
  return (
    <>
      <div
        className="p-lg-3 pb-lg-0 ps-lg-2 pe-lg-2  w-100  position-sticky "
        style={{ top: "0%", background: "#342F2F", zIndex: "1" }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-light m-0">Fabrics</p>
        </div>{" "}
        <hr className="bg-light text-light" />
        <div
          className="d-flex rounded-3 p-1 align-items-center  text-light"
          style={{ fontSize: "0.75em", background: "#0F0F0F" }}
        >
          <div
            className={`col-3 pt-2 pb-2 rounded-2      text-center ${
              activeFabricTab === "Styles" && "active-fabric"
            } `}
          >
            <button
              style={{ border: "0px", background: "none", color: "#fff" }}
              className="m-0 text-center d-block w-100"
              onClick={() => setActiveFabricTab("Styles")}
            >
              Styles
            </button>
          </div>
          <div
            className={`col-3 pt-2 pb-2 rounded-2   text-center ${
              activeFabricTab === "Suits" && "active-fabric"
            } `}
          >
            <button
              style={{ border: "0px", background: "none", color: "#fff" }}
              className="m-0 d-block w-100"
              onClick={() => setActiveFabricTab("Suits")}
            >
              Jackets
            </button>
          </div>{" "}
          <div
            className={`col-3 pt-2 pb-2 rounded-2    text-center ${
              activeFabricTab === "Shirts" && "active-fabric"
            } `}
          >
            <button
              style={{ border: "0px", background: "none", color: "#fff" }}
              className="m-0 d-block w-100"
              onClick={() => setActiveFabricTab("Shirts")}
            >
              Shirts
            </button>
          </div>
          <div
            className={`col-3 pt-2 pb-2 rounded-2    text-center ${
              activeFabricTab === "Trousers" && "active-fabric"
            } `}
          >
            <button
              style={{ border: "0px", background: "none", color: "#fff" }}
              className="m-0 text-center d-block w-100"
              onClick={() => setActiveFabricTab("Trousers")}
            >
              Trousers
            </button>
          </div>
        </div>
        <hr className="bg-light text-light" />
      </div>
      <div className="p-3 pt-0" style={{ zIndex: "-1" }}>
        {activeFabricTab === "Styles" && (
          <div className="styles-lg-list">
            <div
              className={`active-fabric position-relative  rounded-3 mb-3 align-items-center d-flex ${
                styleDetails === "DB_CLOTH" && "bg-dark "
              }`}
              onClick={() => {
                if (activeCanvas === 1) {
                  setCompareStyleDetails("DB_CLOTH");
                } else {
                  setStyleDetails("DB_CLOTH");
                }
              }}
            >
              <div
                className=" rounded-3 mt-2 "
                style={{
                  height: "3px",
                  position: "absolute",
                  top: "5%",
                  left: "5%",
                  background: " #9858FF",
                  translate: "-5% 0%",
                  width: styleDetails === "DB_CLOTH" ? "10%" : "0%",
                  zIndex: "10",
                  transition: "0.3s ease",
                }}
              ></div>

              <div
                className=" rounded-3 mt-2 "
                style={{
                  height: "3px",
                  position: "absolute",
                  top: "5%",
                  left: styleDetails === compareStyleDetails ? "20%" : "5%",
                  background: "#FFC107",
                  translate: "-10% 0%",
                  width:
                    showCompare && compareStyleDetails === "DB_CLOTH"
                      ? "10%"
                      : "0%",
                  zIndex: "10",
                  transition: "0.3s ease",
                }}
              ></div>
              <div className=" col-6 ps-3 ">
                <p className="text-light  text-justify ">Double Breasted</p>
              </div>

              <img
                src="./assets/DB_PREVIEW.png"
                className="img-fluid col-6 d-block mx-auto "
                alt=""
              />
            </div>

            <div
              className={`active-fabric position-relative  rounded-3 mb-3 align-items-center d-flex ${
                styleDetails === "SB_AMERICAN_CLOTH" && "bg-dark  "
              }`}
              onClick={() => {
                if (activeCanvas === 1) {
                  setCompareStyleDetails("SB_AMERICAN_CLOTH");
                } else {
                  setStyleDetails("SB_AMERICAN_CLOTH");
                }
              }}
            >
              <div
                className=" rounded-3 mt-2 "
                style={{
                  height: "3px",
                  position: "absolute",
                  top: "5%",
                  left: "5%",
                  background: " #9858FF",
                  translate: "-5% 0%",
                  width: styleDetails === "SB_AMERICAN_CLOTH" ? "10%" : "0%",
                  zIndex: "10",
                  transition: "0.3s ease",
                }}
              ></div>

              <div
                className=" rounded-3 mt-2 "
                style={{
                  height: "3px",
                  position: "absolute",
                  top: "5%",
                  left: styleDetails === compareStyleDetails ? "20%" : "5%",
                  background: "#FFC107",
                  translate: "-10% 0%",
                  width:
                    showCompare && compareStyleDetails === "SB_AMERICAN_CLOTH"
                      ? "10%"
                      : "0%",
                  zIndex: "10",
                  transition: "0.3s ease",
                }}
              ></div>
              <div className=" col-6 ps-3">
                <p className="text-light   ">Single Breasted American</p>
              </div>

              <img
                src="./assets/AMERICAN_PREVIEW.png"
                className="img-fluid col-6 d-block mx-auto "
                alt=""
              />
            </div>

            <div
              className={`active-fabric position-relative  rounded-3 align-items-center d-flex justify-content-center ${
                styleDetails === "SB_ITALIAN_CLOTH" && "bg-dark "
              }`}
              onClick={() => {
                if (activeCanvas === 1) {
                  setCompareStyleDetails("SB_ITALIAN_CLOTH");
                } else {
                  setStyleDetails("SB_ITALIAN_CLOTH");
                }
              }}
            >
              <div
                className=" rounded-3 mt-2 "
                style={{
                  height: "3px",
                  position: "absolute",
                  top: "5%",
                  left: "5%",
                  background: " #9858FF",
                  translate: "-5% 0%",
                  width: styleDetails === "SB_ITALIAN_CLOTH" ? "10%" : "0%",
                  zIndex: "10",
                  transition: "0.3s ease",
                }}
              ></div>

              <div
                className=" rounded-3 mt-2 "
                style={{
                  height: "3px",
                  position: "absolute",
                  top: "5%",
                  left: styleDetails === compareStyleDetails ? "20%" : "5%",
                  background: "#FFC107",
                  translate: "-10% 0%",
                  width:
                    showCompare && compareStyleDetails === "SB_ITALIAN_CLOTH"
                      ? "10%"
                      : "0%",
                  zIndex: "10",
                  transition: "0.3s ease",
                }}
              ></div>
              <div className=" col-6 ps-3">
                <p className="text-light   ">Single Breasted Italian</p>
              </div>

              <img
                src="./assets/Italian_PREVIEW.png"
                className="img-fluid col-6 d-block mx-auto "
                alt=""
              />
            </div>
          </div>
        )}
        {activeFabricTab !== "Styles" && (
          <div className="row mt-0">
            {fabricData &&
              fabricData.map((item) => {
                return (
                  item.category.includes(activeFabricTab) && (
                    <div
                      className={` ${
                        window.innerWidth > 1500 ? "col-4" : "col-6"
                      }`}
                    >
                      <FabricsCard
                        item={item}
                        showCompare={showCompare}
                        activeFabricTab={activeFabricTab}
                        activeCanvas={activeCanvas}
                        details={
                          activeFabricTab === "Suits"
                            ? suitDetails
                            : activeFabricTab === "Shirts"
                            ? shirtDetails
                            : trouserDetails
                        }
                        compareDetails={
                          activeFabricTab === "Suits"
                            ? compareSuitDetails
                            : activeFabricTab === "Shirts"
                            ? compareShirtDetails
                            : compareTrouserDetails
                        }
                        setFabric={
                          activeFabricTab === "Suits"
                            ? setSuitDetails
                            : activeFabricTab === "Shirts"
                            ? setShirtDetails
                            : setTrouserDetails
                        }
                        setCompareFabric={
                          activeFabricTab === "Suits"
                            ? setCompareSuitDetails
                            : activeFabricTab === "Shirts"
                            ? setCompareShirtDetails
                            : setCompareTrouserDetails
                        }
                      />
                    </div>
                  )
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default DesktopMenu;
