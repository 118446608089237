import { useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GenrateAvatarPopUp = ({  modelId }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();


  return (
    <>
      <div
        className="modal fade"
   
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        // style={{ background: "#5F5B5B" }}
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div className="modal-content bg-dark">
            {
              <div className="">
                <div className="modal-header  justify-content-between">
                  <h5 className="modal-title text-light" id="exampleModalLabel">
                    Generate Avatar
                  </h5>
                  <button
                    className=" p-0"
                    type="button "
                   
                    data-bs-dismiss="modal"
                    style={{
                      border: "0px",
                      outline: "0px",
                      background: "transparent",
                    }}
                  >
                    <Icon
                      icon="ic:round-close"
                      width={30}
                      className="text-light"
                    />
                  </button>
                </div>
                <div className="modal-body " style={{ height: "350px" }}>
                  {/* default avatar selection */}
                  <div className="">
                    <div>
                      <p className="text-light">
                        Select from our sample avatars
                      </p>
                      <div className="mt-2 d-flex" style={{ gap: "1em" }}>
                        <div
                          className="p-1 pb-0 ps-0 pe-0 col-3 card"
                          onClick={() => {
                           

                            dispatch({
                              type: "GET_AVATAR_DETAILS_SUCCESS",
                              payload: {
                                modelId: "plugin-default",
                                weightId: 80,
                              },
                            });
                          }}
                          style={{
                            backgroundColor: "#5F5B5B",
                            border:
                              modelId === "plugin-default" &&
                              "2px solid #9858FF",
                          }}
                        >
                          <img
                            src="https://raymond101.s3.ap-south-1.amazonaws.com/assets/thumbnails/default-model-1.png"
                            className="img-fluid rounded-2"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="model-footer p-2">
                  <div>
                    <button
                     data-bs-dismiss="modal"
                      onClick={() => navigate("/genrate")}
                      className="btn btn-primary w-100 text-light btn-block p-2 "
                      style={{ fontWeight: 500 }}
                    >
                      Generate Your Avatar
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default GenrateAvatarPopUp;
