import React, { useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GenrateAvatarScreen = () => {
  const dispatch = useDispatch();
  const [genrateAvatarSteps, setGenrateAvatarSteps] = useState(1);
  const navigate = useNavigate();
  const [frontImage, setFrontImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);
  const [weight, setWeight] = useState(60);

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const reset = () => {
    setGenrateAvatarSteps(1);
    setFrontImage(null);
    setRightImage(null);
    setLeftImage(null);
    setWeight(60);
  };

  const generateAvatar = async () => {
    try {
      setLoader(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      console.log(frontImage, rightImage, leftImage);

      const formData = new FormData();
      formData.append("FrontFace", frontImage);
      formData.append("LeftFace", leftImage);
      formData.append("RightFace", rightImage);
      formData.append("weight_id", weight);

      const { data } = await axios.post(
        "http://localhost:5000/api/model/create",
        formData,
        config
      );
      console.log(data);

      dispatch({
        type: "GET_AVATAR_DETAILS_SUCCESS",
        payload: {
          modelId: "88ac5ad8-e1fb-45fe-b2da-c87a5316a8b4",
          weightId: weight,
        },
      });

      reset();
      setLoader(false);
      navigate("/");
    } catch (error) {
      setLoader(false);
      setError(true);
    }
  };

  return (
    <div className="bg-dark" style={{ height: "100dvh" }}>
      {loader && (
        <div
          className="position-fixed bg-dark"
          style={{ width: "100%", height: "100dvh", top: "0%", zIndex: "9999" }}
        >
          <div
            className="w-100  p-2"
            style={{ position: "absolute", top: "50%", translate: "0% -50%" }}
          >
            <div class="relative mx-auto " style={{ width: "200px" }}>
              <svg
                style={{
                  position: "absolute",
                  background: "rgba(100, 68, 68, 0)",
                }}
                viewBox="0 0 80 96"
                fill="none"
                aria-hidden="true"
                class="absolute inset-0 h-full w-full"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              >
                <path
                  d="M26.22 78.25c2.679-3.522 1.485-17.776 1.485-17.776-1.084-2.098-1.918-4.288-2.123-5.619-3.573 0-3.7-8.05-3.827-9.937-.102-1.509 1.403-1.383 2.169-1.132-.298-1.3-.92-5.408-1.021-11.446C22.775 24.794 30.94 17.75 40 17.75h.005c9.059 0 17.225 7.044 17.097 14.59-.102 6.038-.723 10.147-1.021 11.446.765-.251 2.271-.377 2.169 1.132-.128 1.887-.254 9.937-3.827 9.937-.205 1.331-1.039 3.521-2.123 5.619 0 0-1.194 14.254 1.485 17.776"
                  class="svg-animation-path"
                  transform="translate(0 1)"
                ></path>
                <path
                  d="M27.705 60.474a26.884 26.884 0 0 0 1.577 2.682c1.786 2.642 5.36 6.792 10.718 6.792h.005c5.358 0 8.932-4.15 10.718-6.792a26.884 26.884 0 0 0 1.577-2.682"
                  class="svg-animation-path"
                  transform="translate(0 1)"
                ></path>
              </svg>
              <svg
                viewBox="0 0 80 96"
                srt
                fill="none"
                aria-hidden="true"
                class="absolute inset-0 h-full w-full"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              >
                <path
                  d="M26.22 78.25c2.679-3.522 1.485-17.776 1.485-17.776-1.084-2.098-1.918-4.288-2.123-5.619-3.573 0-3.7-8.05-3.827-9.937-.102-1.509 1.403-1.383 2.169-1.132-.298-1.3-.92-5.408-1.021-11.446C22.775 24.794 30.94 17.75 40 17.75h.005c9.059 0 17.225 7.044 17.097 14.59-.102 6.038-.723 10.147-1.021 11.446.765-.251 2.271-.377 2.169 1.132-.128 1.887-.254 9.937-3.827 9.937-.205 1.331-1.039 3.521-2.123 5.619 0 0-1.194 14.254 1.485 17.776"
                  class="svg-animation-path1"
                  transform="translate(0 1)"
                ></path>
                <path
                  d="M27.705 60.474a26.884 26.884 0 0 0 1.577 2.682c1.786 2.642 5.36 6.792 10.718 6.792h.005c5.358 0 8.932-4.15 10.718-6.792a26.884 26.884 0 0 0 1.577-2.682"
                  class="svg-animation-path1"
                  transform="translate(0 1)"
                ></path>
              </svg>
            </div>

            <p className="text-center text-light w-100">
              Please wait while we generate your avatar
            </p>
          </div>
        </div>
      )}
      <div
        className=" w-100 justify-content-between p-4 d-flex"
        style={{ position: "absolute" }}
      >
        {genrateAvatarSteps === 1 ? (
          <div
            className="d-flex align-items-center "
            onClick={() => navigate("/")}
          >
            <Icon
              icon="ion:return-up-back"
              width={30}
              className="text-light me-3"
            />
            <h5
              className="modal-title text-light d-flex pt-1 align-items-center"
              id="exampleModalLabel"
            >
              Enter Weight
            </h5>{" "}
          </div>
        ) : genrateAvatarSteps === 2 ? (
          <div
            className="d-flex align-items-center "
            onClick={() => setGenrateAvatarSteps(1)}
          >
            <Icon
              icon="ion:return-up-back"
              width={30}
              className="text-light me-3"
            />
            <h5
              className="modal-title text-light d-flex pt-1 align-items-center"
              id="exampleModalLabel"
            >
              Front Profile
            </h5>{" "}
          </div>
        ) : genrateAvatarSteps === 3 ? (
          <div
            className="d-flex align-items-center "
            onClick={() => setGenrateAvatarSteps(2)}
          >
            <Icon
              icon="ion:return-up-back"
              width={30}
              className="text-light me-3"
            />
            <h5
              className="modal-title text-light d-flex pt-1 align-items-center"
              id="exampleModalLabel"
            >
              Right Profile
            </h5>{" "}
          </div>
        ) : genrateAvatarSteps === 4 ? (
          <div
            className="d-flex align-items-center "
            onClick={() => setGenrateAvatarSteps(3)}
          >
            <Icon
              icon="ion:return-up-back"
              width={30}
              className="text-light me-3"
            />
            <h5
              className="modal-title text-light d-flex pt-1 align-items-center"
              id="exampleModalLabel"
            >
              Left Profile
            </h5>{" "}
          </div>
        ) : (
          ""
        )}

        <Icon
          icon="ic:round-close"
          onClick={() => navigate("/")}
          width={30}
          className="text-light"
        />
      </div>
      <div className="d-flex h-100 justify-content-center align-items-center ">
        <div className="col-6 bg-dark  ">
          {genrateAvatarSteps === 1 ? (
            <div className="">
              {/* <div className="modal-header  justify-content-between">
                <h5 className="modal-title text-light" id="exampleModalLabel">
                  Enter Weight
                </h5>
              </div> */}
              <div className="modal-body " style={{ height: "350px" }}>
                {/* select Weight */}
                <div className="d-flex justify-content-center align-items-center  h-100">
                  <div>
                    <div className="d-flex justify-content-center">
                      <div className="col-6 d-flex align-items-baseline">
                        <input
                          type="tel"
                          className="text-center rounded-0"
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                          style={{
                            fontSize: "3em",
                            background: "none",
                            border: "0px",
                            outline: "0px",
                            color: "#fff",
                            fontWeight: "600",
                            width: "100%",
                            borderBottom: "1px solid grey",
                          }}
                        />
                        <p className="mb-0 " style={{ color: "grey" }}>
                          Kg
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="model-footer p-2">
                <div>
                  <button
                    onClick={() => {
                      if (weight >= 60) {
                        setGenrateAvatarSteps(2);
                      }
                    }}
                    disabled={weight < 60 && true}
                    className="btn btn-primary w-100 text-light btn-block p-2 "
                    style={{ fontWeight: 600 }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : genrateAvatarSteps === 2 ? (
            <div className="">
              <div className="modal-body " style={{ height: "550px" }}>
                {/* select Weight */}
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div>
                    <img
                      src={`${
                        frontImage
                          ? URL.createObjectURL(frontImage)
                          : "./assets/front-face.png"
                      } `}
                      className=" d-block rounded-2 mx-auto img-fluid"
                      style={{ maxHeight: frontImage ? "550px" : " 500px" }}
                      alt="front-face"
                    />
                    <p className="text-light text-center mt-2"></p>
                  </div>
                </div>
              </div>

              <div className="model-footer pt-2">
                <div className="d-flex" style={{ gap: "1em" }}>
                  <button
                    className={`btn ${
                      frontImage ? "btn-secondary" : "btn-primary"
                    }  w-100 text-light btn-block p-2 `}
                    style={{ fontWeight: 500 }}
                  >
                    <label htmlFor="front" className="w-100 h-100 ">
                      Take Photo
                    </label>
                  </button>
                  {frontImage && (
                    <button
                      onClick={() => setGenrateAvatarSteps(3)}
                      className="btn btn-primary w-100 text-light btn-block p-2 "
                      style={{ fontWeight: 500 }}
                    >
                      Next
                    </button>
                  )}

                  <input
                    type="file"
                    id="front"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        setFrontImage(e.target.files[0]);
                      }
                    }}
                    hidden
                    name="selfie"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          ) : genrateAvatarSteps === 3 ? (
            <div className="">
              <div className="modal-body " style={{ height: "550px" }}>
                {/* select Weight */}
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="p-3">
                    <img
                      src={`${
                        rightImage
                          ? URL.createObjectURL(rightImage)
                          : "./assets/right-face.png"
                      } `}
                      className=" rounded-3 d-block mx-auto "
                      alt="front-face"
                      style={{ height: rightImage ? "550px" : " 500px" }}
                    />
                    <p className="text-light text-center mt-2"></p>
                  </div>
                </div>
              </div>

              <div className="model-footer pt-2">
                <div className="d-flex" style={{ gap: "1em" }}>
                  <button
                    // onClick={() => setGenrateAvatarSteps(3)}
                    className={`btn ${
                      rightImage ? "btn-secondary" : "btn-primary"
                    }  w-100 text-light btn-block p-2 `}
                    style={{ fontWeight: 500 }}
                  >
                    <label htmlFor="right" className="w-100 h-100 ">
                      Take Photo
                    </label>
                  </button>
                  {rightImage && (
                    <button
                      onClick={() => setGenrateAvatarSteps(4)}
                      className="btn btn-primary w-100 text-light btn-block p-2 "
                      style={{ fontWeight: 500 }}
                    >
                      Next
                    </button>
                  )}
                  <input
                    type="file"
                    id="right"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        setRightImage(e.target.files[0]);
                      }
                    }}
                    hidden
                    name="selfie"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          ) : genrateAvatarSteps === 4 ? (
            <div className="">
              <div className="modal-body " style={{ height: "550px" }}>
                {/* select Weight */}
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="p-3">
                    <img
                      src={`${
                        leftImage
                          ? URL.createObjectURL(leftImage)
                          : "./assets/left-face.png"
                      } `}
                      className="img-fluid rounded-3 d-block mx-auto"
                      alt="front-face"
                      style={{ maxHeight: leftImage ? "500px" : " 500px" }}
                    />
                    <p className="text-light text-center mt-2"></p>
                  </div>
                </div>
              </div>

              <div className="model-footer p-2">
                <div className="d-flex" style={{ gap: "1em" }}>
                  <button
                    // onClick={() => setGenrateAvatarSteps(3)}
                    className={`btn ${
                      leftImage ? "btn-secondary" : "btn-primary"
                    }  w-100 text-light btn-block  `}
                    style={{ fontWeight: 500 }}
                  >
                    <label htmlFor="left" className="w-100 h-100 ">
                      Take Photo
                    </label>
                  </button>
                  {leftImage && (
                    <button
                      onClick={() => generateAvatar()}
                      className="btn btn-success w-100 text-light btn-block p-2 "
                      style={{ fontWeight: 500 }}
                    >
                      Generate
                    </button>
                  )}

                  <input
                    type="file"
                    id="left"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        setLeftImage(e.target.files[0]);
                      }
                    }}
                    hidden
                    name="selfie"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default GenrateAvatarScreen;
