import React from "react";
import "../css/loader.css";
const Loader = ({ show }) => {
  return (
    <div
      className={`d-flex align-items-center loader-wrapper ${
        !show && "hide-loader"
      } `}
    >
      <span class="main-loader"></span>
    </div>
  );
};

export default Loader;
