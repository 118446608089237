import React, { memo, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Icon } from "@iconify/react";
import "react-loading-skeleton/dist/skeleton.css";
import "../css/fabric-card.css";
const FabricsCard = memo(
  ({
    item,
    details,
    setFabric,
    activeFabricTab,
    compareDetails,
    showCompare,
    setCompareFabric,
    activeCanvas,
  }) => {
    const [showSkeletion, setShowSkeletion] = useState();

    return (
      <div className={`   mb-lg-3 position-relative  `} key={item.skuCode}>
        <div
          className={`rounded-2 overflow-hidden   `}
          style={{
            border:
              details?.skuCode === item.skuCode
                ? "3px solid #9858FF"
                : showCompare && compareDetails?.skuCode === item.skuCode
                ? "3px solid #FFC107"
                : "",
          }}
        >
          {details?.skuCode === item.skuCode ? (
            <div className="position-absolute selected-icon-container ">
              <Icon
                className="selected-icon"
                icon="teenyicons:tick-circle-solid"
                style={{ color: "#9858FF" }}
              />
            </div>
          ) : (
            showCompare &&
            compareDetails?.skuCode === item.skuCode && (
              <div className="position-absolute selected-icon-container ">
                <Icon
                  icon="teenyicons:tick-circle-solid"
                  className="selected-icon"
                  style={{ color: "#FFC107" }}
                />
              </div>
            )
          )}

          <img
            src={`https://raymond101.s3.ap-south-1.amazonaws.com/
														${activeFabricTab}/${item.skuCode}/Preview.jpg`}
            className={`img-fluid  opacity-0  d-block mx-auto  cursor-pointer`}
            alt=""
            onLoad={(e) => {
              e.target.classList.remove("opacity-0");
              setShowSkeletion(true);
            }}
            onClick={() => {
              if (showCompare) {
                if (activeCanvas === 1) {
                  setCompareFabric(item);
                } else {
                  setFabric(item);
                }
              } else {
                setFabric(item);
              }
            }}
          />
        </div>
        {!showSkeletion && (
          <div
            style={{ top: "0%", left: "0%" }}
            className={`w-100 mb-lg-3  ps-1 pe-1 mb-3 ${showSkeletion}  position-absolute h-100`}
          >
            {" "}
            <SkeletonTheme
              baseColor="#202020"
              highlightColor="#444"
              height={"100%"}
            >
              <Skeleton count={1} />
            </SkeletonTheme>
          </div>
        )}
      </div>
    );
  }
);

export default FabricsCard;
