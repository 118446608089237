import React, { memo, useRef, useEffect, useState, useMemo } from "react";

import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import * as THREE from "three";

import * as SkeletonUtils from "three/addons/utils/SkeletonUtils.js";


import "animate.css";


import { useSelector, useDispatch } from "react-redux";
import { Center } from "@react-three/drei";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";


const cloneMaterials = (materials) => {
  const clonedMaterials = {};
  for (const key in materials) {
    if (materials.hasOwnProperty(key)) {
      // Clone each material
      clonedMaterials[key] = materials[key].clone();
    }
  }
  return clonedMaterials;
};

const CompareScene = memo(
  ({
    suitDetails,
    trouserDetails,
    shirtDetails,
    styleDetails,
    compareStyleDetails,
  }) => {
    // const [productModel, setProductModel] = useState();

    const { source: sourceModel } = useSelector((state) => state.sourceModel);
    const loader = new GLTFLoader();
    const productRef = useRef();

    const { target: targetModel } = useSelector((state) => state.targetModel);
    const { product } = useSelector((state) => state.productModel);
    const { avatar } = useSelector((state) => state.avatarModelDetails);
    const {
      bgModel,
      loading: backgroundModelLoading,
      error: backgroundModelError,
    } = useSelector((state) => state.backgroundModel);
    const textureLoader = new THREE.TextureLoader();

    const productModel = useLoader(
      GLTFLoader,
      `https://raymond101.s3.ap-south-1.amazonaws.com/assets/${avatar.weightId}KG/${avatar.weightId}KG_${compareStyleDetails}.glb`
    );

    //adding texture to product
    useEffect(() => {
      if (compareStyleDetails === styleDetails) return;

      if (!productModel) return;

      console.log(compareStyleDetails)

      if (productModel) {
        let styleName;
        switch (compareStyleDetails) {
          case "SB_AMERICAN_CLOTH":
            styleName = "Style_1";
            break;
          case "SB_ITALIAN_CLOTH":
            styleName = "Style_2";
            break;
          case "DB_CLOTH":
            styleName = "Style_3";
            break;
          default:
            styleName = undefined;
            break;
        }

        const applyTextures = (materialName, textureUrl) => {

       
          // Apply Textures to Suit
          textureLoader.load(textureUrl, (loadedTexture) => {
            // Apply the additional texture settings

            console.log(productModel);

            loadedTexture.flipY = false;
            loadedTexture.wrapS = THREE.RepeatWrapping;
            loadedTexture.wrapT = THREE.RepeatWrapping;
            loadedTexture.repeat.set(8, 8);
            loadedTexture.colorSpace = THREE.SRGBColorSpace;

            // Check if the material exists and apply the texture
            if (
              productModel.materials &&
              productModel.materials[materialName]
            ) {
              productModel.materials[materialName].map = loadedTexture;
              productModel.materials[materialName].roughness = 1;
              productModel.materials[materialName].metalness = 0;
              productModel.materials[materialName].emissiveIntensity = 0;

              productModel.materials[materialName].needsUpdate = true;
            }

            //applying normal map
            if (styleName) {
              console.log("hiiii");
              textureLoader.load(
                `https://protal-web-assest.s3.ap-south-1.amazonaws.com/plugin/Normal-Maps/${materialName}/${styleName}_${avatar.weightId}.png`,
                (loadedTexture) => {
                  // Apply the additional texture settings
                  loadedTexture.flipY = false;
                  loadedTexture.colorSpace = THREE.LinearSRGBColorSpace;

                  console.log(loadedTexture);

                  // Check if the material exists and apply the texture
                  if (
                    productModel.materials &&
                    productModel.materials[materialName]
                  ) {
                    productModel.materials[materialName].normalMap =
                      loadedTexture;

                    productModel.materials[materialName].needsUpdate = true;
                  }
                }
              );
            }
          });
        };

        suitDetails &&
          applyTextures(
            "Jacket",
            `https://raymond101.s3.ap-south-1.amazonaws.com/Suits/${suitDetails?.skuCode}/Albedo.jpg`
          );

        shirtDetails &&
          applyTextures(
            "Shirt",
            `https://raymond101.s3.ap-south-1.amazonaws.com/Shirts/${shirtDetails?.skuCode}/Albedo.jpg`
          );

        trouserDetails &&
          applyTextures(
            "Trouser",
            `https://raymond101.s3.ap-south-1.amazonaws.com/Trousers/${trouserDetails?.skuCode}/Albedo.jpg`
          );
      }
    }, [
      productModel,
      suitDetails,
      shirtDetails,
      trouserDetails,
      compareStyleDetails,
      styleDetails,
    ]);

    // Clone the materials for this instance
    const clonedMaterials = cloneMaterials(product.materials);

    const cloneProductModel = product.scene.clone();

    useEffect(() => {
      // Apply the cloned materials to the cloned model
      cloneProductModel.traverse((child) => {
        if (child.isMesh && clonedMaterials[child.material.name]) {
          child.material = clonedMaterials[child.material.name];
          child.material.map = null;
          child.material.normalMap = null;
          
          let styleName = "Style_3";
          if (child.material.name === "Jacket") {
            textureLoader.load(
              `https://raymond101.s3.ap-south-1.amazonaws.com/Suits/${suitDetails?.skuCode}/Albedo.jpg`,
              (loadedTexture) => {
                // Apply the additional texture settings

                loadedTexture.flipY = false;
                loadedTexture.wrapS = THREE.RepeatWrapping;
                loadedTexture.wrapT = THREE.RepeatWrapping;
                loadedTexture.repeat.set(8, 8);
                loadedTexture.colorSpace = THREE.SRGBColorSpace;

                // Check if the material exists and apply the texture

                child.material.map = loadedTexture;
                child.material.roughness = 1;
                child.material.metalness = 0;
                child.material.emissiveIntensity = 0;

                child.material.needsUpdate = true;
              }
            );

            textureLoader.load(
              `https://protal-web-assest.s3.ap-south-1.amazonaws.com/plugin/Normal-Maps/Jacket/${styleName}_${avatar.weightId}.png`,
              (loadedTexture) => {
                // Apply the additional texture settings
                loadedTexture.flipY = false;
                loadedTexture.colorSpace = THREE.LinearSRGBColorSpace;

                // Check if the material exists and apply the texture

                child.material.normalMap = loadedTexture;

                child.material.needsUpdate = true;
              }
            );
          }

          if (child.material.name === "Shirt") {
            textureLoader.load(
              `https://raymond101.s3.ap-south-1.amazonaws.com/Shirts/${shirtDetails?.skuCode}/Albedo.jpg`,
              (loadedTexture) => {
                // Apply the additional texture settings

                loadedTexture.flipY = false;
                loadedTexture.wrapS = THREE.RepeatWrapping;
                loadedTexture.wrapT = THREE.RepeatWrapping;
                loadedTexture.repeat.set(8, 8);
                loadedTexture.colorSpace = THREE.SRGBColorSpace;

                // Check if the material exists and apply the texture

                child.material.map = loadedTexture;
                child.material.roughness = 1;
                child.material.metalness = 0;
                child.material.emissiveIntensity = 0;

                child.material.needsUpdate = true;
              }
            );
          }

          if (child.material.name === "Trouser") {
            textureLoader.load(
              `https://raymond101.s3.ap-south-1.amazonaws.com/Trousers/${trouserDetails?.skuCode}/Albedo.jpg`,
              (loadedTexture) => {
                // Apply the additional texture settings

                loadedTexture.flipY = false;
                loadedTexture.wrapS = THREE.RepeatWrapping;
                loadedTexture.wrapT = THREE.RepeatWrapping;
                loadedTexture.repeat.set(8, 8);
                loadedTexture.colorSpace = THREE.SRGBColorSpace;

                // Check if the material exists and apply the texture

                child.material.map = loadedTexture;
                child.material.roughness = 1;
                child.material.metalness = 0;
                child.material.emissiveIntensity = 0;

                child.material.needsUpdate = true;
              }
            );
          }

          child.material.needsUpdate = true; // Update the material
        }
      });
    }, [
      compareStyleDetails,
      styleDetails,
      cloneProductModel,
      suitDetails,
      shirtDetails,
      trouserDetails,
    ]);

    return (
      <Center>
        {targetModel && <primitive object={targetModel?.scene.clone()} />}

        {productModel && cloneProductModel && (
          <primitive
            object={
              compareStyleDetails === styleDetails
                ? cloneProductModel
                : productModel.scene.clone()
            }
          />
        )}

        {bgModel && (
          <primitive object={bgModel.scene.clone()}  />
        )}
      </Center>
    );
  }
);

export default CompareScene;
