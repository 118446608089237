import React, { useRef, useEffect } from "react";
import { SpotLightHelper } from "three";
import { useHelper } from "@react-three/drei";
import { useControls, } from "leva";

export const Lights = ({ lightColor }) => {
  // Create refs for spotlights
  const spotLightRef1 = useRef();
  const spotLightRef2 = useRef();
  const spotLightRef3 = useRef();
  const spotLightRef4 = useRef();
  const spotLightRef5 = useRef();

  // Add helpers for each spotlight if enabled
  const useSpotLightHelper = (ref, enabled) => {
    useHelper(enabled ? ref : null, SpotLightHelper);
  };



  // Define controls for each spotlight
  const spotlight1 = useControls("Spotlight 1", {
    enabled: true,
    castShadow: true,
    helper: true,
    position: { value: [1.8, 1.3, 3.7], step: 0.1 },
    target: { value: [0, -2.7, 0], step: 0.1 },
    angle: { value: 0.36, min: 0, max: Math.PI / 2, step: 0.01 },
    penumbra: { value: 1, min: 0, max: 1, step: 0.1 },
    intensity: { value: 21, min: 0, max: 100, step: 1 },
    decay: { value: 2, min: 0, max: 2, step: 0.1 },
    distance: { value:11, min: 0, max: 100, step: 0.1 },
    shadowMapWidth: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowMapHeight: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowCameraNear: { value: 1, min: 0.1, max: 10, step: 0.1 },
    shadowCameraFar: { value: 10, min: 1, max: 100, step: 1 },
    shadowBias: { value: -0.001, min: -0.01, max: 0.01, step: 0.0001 },
  });

  const spotlight2 = useControls("Spotlight 2", {
    enabled: true,
    castShadow: false,
    helper: false,
    position: { value: [-0.4, 6.6, 0.6], step: 0.1 },
    target: { value: [0, -2, 0], step: 0.1 },
    angle: { value: 1.11, min: 0, max: Math.PI / 2, step: 0.01 },
    penumbra: { value: 1, min: 0, max: 1, step: 0.1 },
    intensity: { value: 100, min: 0, max: 100, step: 1 },
    decay: { value: 2, min: 0, max: 2, step: 0.1 },
    distance: { value: 29, min: 0, max: 100, step: 1 },
    shadowMapWidth: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowMapHeight: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowCameraNear: { value: 1, min: 0.1, max: 10, step: 0.1 },
    shadowCameraFar: { value: 10, min: 1, max: 100, step: 1 },
    shadowBias: { value: -0.001, min: -0.01, max: 0.01, step: 0.0001 },
  });

  const spotlight3 = useControls("Spotlight 3", {
    enabled: true,
    castShadow: false,
    helper: false,
    position: { value: [0, -0.8, 5.7], step: 0.1 },
    target: { value: [0, -2.5, 0], step: 0.1 },
    angle: { value: 0.28, min: 0, max: Math.PI / 2, step: 0.01 },
    penumbra: { value: 0.8, min: 0, max: 1, step: 0.1 },
    intensity: { value: 6, min: 0, max: 100, step: 1 },
    decay: { value: 1.5, min: 0, max: 2, step: 0.1 },
    distance: { value: 9, min: 0, max: 100, step: 1 },
    shadowMapWidth: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowMapHeight: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowCameraNear: { value: 1, min: 0.1, max: 10, step: 0.1 },
    shadowCameraFar: { value: 10, min: 1, max: 100, step: 1 },
    shadowBias: { value: -0.001, min: -0.01, max: 0.01, step: 0.0001 },
  });

  const spotlight4 = useControls("Spotlight 4", {
    enabled: true,
    castShadow: false,
    helper: false,
    position: { value: [0, -1.5, 0.9], step: 0.1 },
    target: { value: [0, -1.5, 0], step: 0.1 },
    angle: { value: 0.12, min: 0, max: Math.PI / 2, step: 0.01 },
    penumbra: { value: 1, min: 0, max: 1, step: 0.1 },
    intensity: { value: 1, min: 0, max: 100, step: 1 },
    decay: { value: 2, min: 0, max: 2, step: 0.1 },
    distance: { value: 2, min: 0, max: 100, step: 1 },
    shadowMapWidth: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowMapHeight: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowCameraNear: { value: 1, min: 0.1, max: 10, step: 0.1 },
    shadowCameraFar: { value: 10, min: 1, max: 100, step: 1 },
    shadowBias: { value: -0.001, min: -0.01, max: 0.01, step: 0.0001 },
  });

  const spotlight5 = useControls("Spotlight 5", {
    enabled: true,
    castShadow: false,
    helper: false,
    position: { value: [-1, -1.7, -4.9], step: 0.1 },
    target: { value: [0, -2, 0], step: 0.1 },
    angle: { value: 0.74, min: 0, max: Math.PI / 2, step: 0.01 },
    penumbra: { value: 0.9, min: 0, max: 1, step: 0.1 },
    intensity: { value: 4, min: 0, max: 100, step: 1 },
    decay: { value: 1, min: 0, max: 2, step: 0.1 },
    distance: { value: 15, min: 0, max: 100, step: 1 },
    shadowMapWidth: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowMapHeight: { value: 1080, min: 256, max: 4096, step: 1 },
    shadowCameraNear: { value: 1, min: 0.1, max: 10, step: 0.1 },
    shadowCameraFar: { value: 10, min: 1, max: 100, step: 1 },
    shadowBias: { value: -0.001, min: -0.01, max: 0.01, step: 0.0001 },
  });

  useEffect(() => {
    if (spotLightRef1.current?.target) {
      const [x, y, z] = spotlight1.target;
      spotLightRef1.current.target.position.set(x, y, z);
      spotLightRef1.current.target.updateMatrixWorld(); // Ensure the target updates in the scene
    }
    if (spotLightRef2.current?.target) {
      const [x, y, z] = spotlight2.target;
      spotLightRef2.current.target.position.set(x, y, z);
      spotLightRef2.current.target.updateMatrixWorld(); // Ensure the target updates in the scene
    }

    if (spotLightRef3.current?.target) {
      const [x, y, z] = spotlight3.target;
      spotLightRef3.current.target.position.set(x, y, z);
      spotLightRef3.current.target.updateMatrixWorld(); // Ensure the target updates in the scene
    }

    if (spotLightRef4.current?.target) {
      const [x, y, z] = spotlight4.target;
      spotLightRef4.current.target.position.set(x, y, z);
      spotLightRef4.current.target.updateMatrixWorld(); // Ensure the target updates in the scene
    }

    if (spotLightRef5.current?.target) {
      const [x, y, z] = spotlight5.target;
      spotLightRef5.current.target.position.set(x, y, z);
      spotLightRef5.current.target.updateMatrixWorld(); // Ensure the target updates in the scene
    }
  }, [
    spotLightRef1,
    spotlight1?.target,
    spotLightRef2,
    spotlight2?.target,
    spotLightRef3,
    spotlight3?.target,
    spotLightRef4,
    spotlight4?.target,
    spotLightRef5,
    spotlight5?.target,
  ]);

  useSpotLightHelper(spotLightRef1, spotlight1.helper);
  useSpotLightHelper(spotLightRef2, spotlight2.helper);
  useSpotLightHelper(spotLightRef3, spotlight3.helper);
  useSpotLightHelper(spotLightRef4, spotlight4.helper);
  useSpotLightHelper(spotLightRef5, spotlight5.helper);

  return (
    <>
    <ambientLight intensity={0.2} />
      {spotlight1.enabled && (
        <spotLight
          ref={spotLightRef1}
          position={spotlight1.position}
          angle={spotlight1.angle}
          penumbra={spotlight1.penumbra}
          color={lightColor}
          decay={spotlight1.decay}
          distance={spotlight1.distance}
          intensity={spotlight1.intensity}
          castShadow={spotlight1.castShadow}
          shadow-mapSize-width={spotlight1.shadowMapWidth}
          shadow-mapSize-height={spotlight1.shadowMapHeight}
          shadow-camera-near={spotlight1.shadowCameraNear}
          shadow-camera-far={spotlight1.shadowCameraFar}
          shadow-bias={spotlight1.shadowBias}
          
        />
      )}
      {spotlight2.enabled && (
        <spotLight
          ref={spotLightRef2}
          position={spotlight2.position}
          angle={spotlight2.angle}
          penumbra={spotlight2.penumbra}
          color={lightColor}
          decay={spotlight2.decay}
          distance={spotlight2.distance}
          intensity={spotlight2.intensity}
          castShadow={spotlight2.castShadow}
          shadow-mapSize-width={spotlight2.shadowMapWidth}
          shadow-mapSize-height={spotlight2.shadowMapHeight}
          shadow-camera-near={spotlight2.shadowCameraNear}
          shadow-camera-far={spotlight2.shadowCameraFar}
          shadow-bias={spotlight2.shadowBias}
        />
      )}
      {spotlight3.enabled && (
        <spotLight
          ref={spotLightRef3}
          position={spotlight3.position}
          angle={spotlight3.angle}
          penumbra={spotlight3.penumbra}
          color={lightColor}
          decay={spotlight3.decay}
          distance={spotlight3.distance}
          intensity={spotlight3.intensity}
          castShadow={spotlight3.castShadow}
          shadow-mapSize-width={spotlight3.shadowMapWidth}
          shadow-mapSize-height={spotlight3.shadowMapHeight}
          shadow-camera-near={spotlight3.shadowCameraNear}
          shadow-camera-far={spotlight3.shadowCameraFar}
          shadow-bias={spotlight3.shadowBias}
        />
      )}
      {spotlight4.enabled && (
        <spotLight
          ref={spotLightRef4}
          position={spotlight4.position}
          angle={spotlight4.angle}
          penumbra={spotlight4.penumbra}
          color={lightColor}
          decay={spotlight4.decay}
          distance={spotlight4.distance}
          intensity={spotlight4.intensity}
          castShadow={spotlight4.castShadow}
          shadow-mapSize-width={spotlight4.shadowMapWidth}
          shadow-mapSize-height={spotlight4.shadowMapHeight}
          shadow-camera-near={spotlight4.shadowCameraNear}
          shadow-camera-far={spotlight4.shadowCameraFar}
          shadow-bias={spotlight4.shadowBias}
        />
      )}
      {spotlight5.enabled && (
        <spotLight
          ref={spotLightRef5}
          position={spotlight5.position}
          angle={spotlight5.angle}
          penumbra={spotlight5.penumbra}
          shadow-radius={5}
          color={lightColor}
          decay={spotlight5.decay}
          distance={spotlight5.distance}
          intensity={spotlight5.intensity}
          castShadow={spotlight5.castShadow}
          shadow-mapSize-width={spotlight5.shadowMapWidth}
          shadow-mapSize-height={spotlight5.shadowMapHeight}
          shadow-camera-near={spotlight5.shadowCameraNear}
          shadow-camera-far={spotlight5.shadowCameraFar}
          shadow-bias={spotlight5.shadowBias}
        />
      )}
    </>
  );
};
