import React, { useRef, useEffect, useState, useMemo } from "react";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import * as THREE from "three";
import Camera from "./Camera";
import * as SkeletonUtils from "three/addons/utils/SkeletonUtils.js";
import SpotLight from "../scene-components/SpotLight";

import "animate.css";

import { Skeleton, Matrix4, Color } from "three";
import { useSelector, useDispatch } from "react-redux";
import { Center } from "@react-three/drei";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { TextureLoader, RepeatWrapping, MeshStandardMaterial } from "three";

const FinalScene = ({
  avatarTexture,

  poseType,

  hairStylesList,
  suitDetails,
  shirtDetails,
  trouserDetails,
  shoesColor,
  currentHairStyle,
  styleDetails,
}) => {

  
  const [shoesState, setShoesState] = useState(false);
  const [poseState, setPoseState] = useState(false);
  const [productState, setProductState] = useState(false);
  const [hairState, setHairState] = useState(false);

  const dispatch = useDispatch();

  const sourceModels = useSelector((state) => state.sourceModels);

  const sourceModelState = useSelector((state) => state.sourceModel);

  const {
    source: sourceModel,
    loading: sourceLoading,
    error: sourceError,
  } = sourceModelState;

  const targetModelState = useSelector((state) => state.targetModel);
  const {
    target: targetModel,
    loading: taregtLoading,
    error: taregtError,
  } = targetModelState;
  const {
    product: productModel,
    loading: productLoading,
    error: productError,
  } = useSelector((state) => state.productModel);

  const {
    bgModel: backgroundModel,
    loading: backgroundModelLoading,
    error: backgroundModelError,
  } = useSelector((state) => state.backgroundModel);

  const { avatar } = useSelector((state) => state.avatarModelDetails);

  THREE.Cache.enabled = true;
  const loader = new GLTFLoader();

  THREE.ColorManagement.enabled = true;

  const textureLoader = new THREE.TextureLoader();

  const product = useLoader(
    GLTFLoader,
    `https://raymond101.s3.ap-south-1.amazonaws.com/assets/${avatar.weightId}KG/${avatar.weightId}KG_${styleDetails}.glb`
  );

  const bgModel = useLoader(
    GLTFLoader,
    "https://raymond101.s3.ap-south-1.amazonaws.com/assets/False_Background.glb"
  );

  useEffect(() => {
    if (product) {
      dispatch({
        type: "GET_PRODUCT_DETAILS_SUCCESS",
        payload: product,
      });
    }

    if (bgModel) {
      dispatch({
        type: "SET_BACKGROUND_SUCCESS",
        payload: bgModel,
      });
    }
  }, [dispatch, product, bgModel]);

  const texture = useLoader(TextureLoader, avatarTexture);
  texture.flipY = false;
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(1, 1);
  texture.colorSpace = THREE.SRGBColorSpace;

  useEffect(() => {
    loader.load(
      `https://raymond101.s3.ap-south-1.amazonaws.com/output_uploads/${avatar.modelId}/model.glb`,
      function (model) {
        console.log("loading target");
        dispatch({
          type: "GET_TARGET_DETAILS_SUCCESS",
          payload: model,
        });
      },
      function (error) {
        dispatch({
          type: "GET_TARGET_DETAILS_FAIL",
          payload: error,
        });
      }
    );
  }, [avatar, dispatch]);

  useEffect(() => {
    loader.load(
      sourceModels.sources.filter((item) => item.weight === avatar.weightId)[0]
        .modelUrl,
      function (model) {
        console.log("loading source");
        dispatch({
          type: "GET_SOURCE_DETAILS_SUCCESS",
          payload: model,
        });
      },

      function (error) {
        dispatch({
          type: "GET_SOURCE_DETAILS_FAIL",
          payload: error,
        });
      }
    );
  }, [avatar]);

  useEffect(() => {
    if (!productModel && !targetModel && bgModel) return;

    if (targetModel) {
      targetModel.scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;
        }
      });

      productModel.scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;
        }
      });

      bgModel.scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;
          node.material = new MeshStandardMaterial({
            // map: texture,

            color: "#7F7171",
            // color:"#ededed",
          });
          node.material.needsUpdate = true;
        }
      });
      targetModel.scene.traverse((node) => {
        if (node.isMesh && node.name.startsWith("FBHead")) {
          node.material = new MeshStandardMaterial({
            map: texture,

            // roughness: 1, // Adjust as needed
            // metalness: 0, // Adjust as needed
            color: texture,
          });
          node.material.needsUpdate = true;
        }
      });
    }
  }, [targetModel, texture, productModel]);

  // adding hair
  useEffect(() => {
    if (!targetModel && !sourceModel) {
      return;
    }
    setHairState(true);

    const hairStylesName = [
      {
        scalp: "Hair_Classic_Short_1",
        hair: "Hair_Classic_Short_2",
        hairMapOpacity:
          "https://protal-web-assest.s3.ap-south-1.amazonaws.com/Hair-Textures/Hair_Classic_Short/Classic_Short_opacity.jpg",
        hairPosition: {
          x: -0.02,
          y: 0.055,
          z: 0,
        },
      },
      {
        scalp: "Hair_Curly_Fade_1",
        hair: "Hair_Curly_Fade_2",
        hairMapOpacity:
          "https://protal-web-assest.s3.ap-south-1.amazonaws.com/Hair-Textures/Hair_Curly_Fade/Curly_Fade_opacity.jpg",
        hairPosition: {
          x: -0.02,
          y: 0.07,
          z: 0,
        },
      },
      {
        scalp: "Hair_Receding_1",
        hair: "Hair_Receding_2",
        hairMapOpacity:
          "https://protal-web-assest.s3.ap-south-1.amazonaws.com/Hair-Textures/Hair_Receding/Receding_opacity.jpg",
        hairPosition: {
          x: -0.035,
          y: 0.065,
          z: 0,
        },
      },
    ];

    // Load a glTF resource
    loader.load(
      // resource URL
      hairStylesList[currentHairStyle],
      // called when the resource is loaded
      function (hairStyleModel) {
        const headBone = targetModel.scene.getObjectByName("Head_M");

        // removing hairstyles
        headBone.children.pop();
        headBone.children.pop();

        const hairStyleMeshScalp = hairStyleModel.scene.getObjectByName(
          hairStylesName[currentHairStyle].scalp
        );
        const hairStyleMeshHair = hairStyleModel.scene.getObjectByName(
          hairStylesName[currentHairStyle].hair
        );

        const scalpDiffuse = textureLoader.load(
          "https://protal-web-assest.s3.ap-south-1.amazonaws.com/Hair-Textures/Scalp/Scalp_diffuse.jpg"
        );
        const scalpOpacity = textureLoader.load(
          "https://protal-web-assest.s3.ap-south-1.amazonaws.com/Hair-Textures/Scalp/Scalp_opacity.jpg"
        );
        const hairOpacity = textureLoader.load(
          hairStylesName[currentHairStyle].hairMapOpacity
        );

        hairOpacity.flipY = false;
        scalpOpacity.flipY = false;

        scalpOpacity.wrapS = RepeatWrapping;
        scalpOpacity.wrapT = RepeatWrapping;

        hairStyleMeshHair.geometry.center();
        hairStyleMeshScalp.geometry.center();
        hairStyleMeshHair.rotation.set(Math.PI / 2, 0, Math.PI / -2);
        hairStyleMeshHair.position.set(
          hairStylesName[currentHairStyle].hairPosition.x,
          hairStylesName[currentHairStyle].hairPosition.y,
          hairStylesName[currentHairStyle].hairPosition.z
        );

        hairStyleMeshHair.material.transparent = true;
        hairStyleMeshHair.material.alphaMap = hairOpacity;
        hairStyleMeshHair.material.color = new THREE.Color("rgb(8, 8, 6)");
        hairStyleMeshHair.material.blendAlpha = 1;
        hairStyleMeshHair.material.roughness = 1;
        hairStyleMeshHair.material.depthWrite = false;
        hairStyleMeshHair.material.opacity = 1;

        hairStyleMeshScalp.rotation.set(0, 0, -90);
        hairStyleMeshScalp.rotation.set(Math.PI / 2, 0, Math.PI / -2);
        hairStyleMeshScalp.position.set(-0.01, 0.04, 0);
        hairStyleMeshScalp.material.transparent = true;
        hairStyleMeshScalp.material.map = scalpDiffuse;
        hairStyleMeshHair.material.blendAlpha = 1;
        hairStyleMeshScalp.material.side = THREE.BackSide;
        hairStyleMeshScalp.material.alphaMap = scalpOpacity;
        hairStyleMeshScalp.material.depthWrite = true;
        hairStyleMeshScalp.material.opacity = 1;

        // hairStyleMeshScalp
        hairStyleMeshHair.updateMatrixWorld(true);
        hairStyleMeshScalp.updateMatrixWorld(true);

        // Add the hairstyle mesh as a child of the Head_M bone
        headBone.add(hairStyleMeshHair);
        headBone.add(hairStyleMeshScalp);

        // Update the world matrix of the bone to ensure correct alignment
        headBone.updateMatrixWorld(true);
      },
      // called while loading is progressing
      function (xhr) {
        if ((xhr.loaded / xhr.total) * 100 == 100) {
        }
      },

      function (error) {
        console.error(error);
      }
    );
    setHairState(false);
  }, [currentHairStyle, targetModel, sourceModel]);

  // adding shoes
  useEffect(() => {
    if (!sourceModel && !targetModel && !poseState) return;

    const textureLoader = new THREE.TextureLoader();

    const baseTextures = [
      "https://protal-web-assest.s3.ap-south-1.amazonaws.com/shoes/black_maps/shoe_model_L_shoes1SG_BaseColor.jpg",
      "https://protal-web-assest.s3.ap-south-1.amazonaws.com/shoes/Brown_maps/shoe_model_L_shoes1SG_BaseColor.jpg",
      "https://protal-web-assest.s3.ap-south-1.amazonaws.com/shoes/tan_shoe/shoe_model_L_shoes1SG_BaseColor.jpg",
    ];
    const normalMapTextures = [
      "https://protal-web-assest.s3.ap-south-1.amazonaws.com/shoes/black_maps/shoe_model_L_shoes1SG_Normal.jpg",
      "https://protal-web-assest.s3.ap-south-1.amazonaws.com/shoes/Brown_maps/shoe_model_L_shoes1SG_Normal.jpg",
      "https://protal-web-assest.s3.ap-south-1.amazonaws.com/shoes/tan_shoe/shoe_model_L_shoes1SG_Normal.jpg",
    ];

    if (
      !targetModel?.scene.getObjectByName("Shoe_Left") &&
      !targetModel?.scene.getObjectByName("Shoe_Right")
    ) {
      // Load the texture
      const shoesBaseTexture = textureLoader.load(baseTextures[shoesColor]);
      const shoesNormalMap = textureLoader.load(normalMapTextures[shoesColor]);

      shoesBaseTexture.flipY = false;
      shoesBaseTexture.wrapS = THREE.RepeatWrapping;
      shoesBaseTexture.wrapT = THREE.RepeatWrapping;
      shoesBaseTexture.colorSpace = THREE.LinearSRGBColorSpace;

      shoesNormalMap.flipY = false;
      shoesNormalMap.wrapS = THREE.RepeatWrapping;
      shoesNormalMap.wrapT = THREE.RepeatWrapping;
      shoesNormalMap.colorSpace = THREE.LinearSRGBColorSpace;

      if (!sourceModel) return;

      sourceModel.scene.traverse((node) => {
        if (
          node.isMesh &&
          (node.name === "Shoe_Right" || node.name === "Shoe_Left")
        ) {
          const clone = node.clone();

          // Apply the texture to the material
          clone.material.map = shoesBaseTexture;
          clone.material.normalMap = shoesNormalMap;
          node.material.metalness = 0;
          // clone.position.copy(targetModel.scene.position);
          node.material.emissive.setHex("#ffffff");
          node.material.roughness = 0.5;
          node.material.emissiveIntensity = 1;

          console.log(node.material);

          targetModel?.scene.add(clone);
          clone.updateMatrixWorld(true);
          targetModel?.scene.updateMatrixWorld(true);
        }
      });
    } else {
      // Load the texture
      const shoesBaseTexture = textureLoader.load(baseTextures[shoesColor]);
      const shoesNormalMap = textureLoader.load(normalMapTextures[shoesColor]);
      shoesBaseTexture.flipY = false;

      shoesBaseTexture.wrapS = THREE.RepeatWrapping;
      shoesBaseTexture.wrapT = THREE.RepeatWrapping;
      shoesBaseTexture.colorSpace = THREE.LinearSRGBColorSpace;
      shoesNormalMap.flipY = false;
      shoesNormalMap.wrapS = THREE.RepeatWrapping;
      shoesNormalMap.wrapT = THREE.RepeatWrapping;
      shoesNormalMap.colorSpace = THREE.LinearSRGBColorSpace;

      targetModel.scene.traverse((node) => {
        if (
          node.isMesh &&
          (node.name === "Shoe_Right" || node.name === "Shoe_Left")
        ) {
          node.material.map = shoesBaseTexture;
          node.material.normalMap = shoesNormalMap;

          node.material.roughness = 0.5;
          node.material.metalness = 0;
          // node.material.emissiveIntensity = 1

          node.material.needsUpdate = true;

          node.updateMatrixWorld(true);
          targetModel.scene.updateMatrixWorld(true);
        }
      });
      setShoesState(true);
    }
  }, [sourceModel, targetModel, shoesColor, poseState]);

  //adding texture to product
  useEffect(() => {
    if (!productModel) return;

    if (productModel) {
      let styleName;
      switch (styleDetails) {
        case "SB_AMERICAN_CLOTH":
          styleName = "Style_1";
          break;
        case "SB_ITALIAN_CLOTH":
          styleName = "Style_2";
          break;
        case "DB_CLOTH":
          styleName = "Style_3";
          break;
        default:
          styleName = undefined;
          break;
      }

      const applyTextures = (materialName, textureUrl) => {
        // Apply Textures to Suit
        textureLoader.load(textureUrl, (loadedTexture) => {
          // Apply the additional texture settings

          loadedTexture.flipY = false;
          loadedTexture.wrapS = THREE.RepeatWrapping;
          loadedTexture.wrapT = THREE.RepeatWrapping;
          loadedTexture.repeat.set(8, 8);
          loadedTexture.colorSpace = THREE.SRGBColorSpace;

          // Check if the material exists and apply the texture
          if (productModel.materials && productModel.materials[materialName]) {
            productModel.materials[materialName].map = loadedTexture;
            productModel.materials[materialName].roughness = 1;
            productModel.materials[materialName].metalness = 0;
            productModel.materials[materialName].emissiveIntensity = 0;

            productModel.materials[materialName].needsUpdate = true;
          }

          //applying normal map
          if (styleName) {
            textureLoader.load(
              `https://protal-web-assest.s3.ap-south-1.amazonaws.com/plugin/Normal-Maps/${materialName}/${styleName}_${avatar.weightId}.png`,
              (loadedTexture) => {
                // Apply the additional texture settings
                loadedTexture.flipY = false;
                loadedTexture.colorSpace = THREE.LinearSRGBColorSpace;

                console.log(loadedTexture);

                // Check if the material exists and apply the texture
                if (
                  productModel.materials &&
                  productModel.materials[materialName]
                ) {
                  productModel.materials[materialName].normalMap =
                    loadedTexture;

                  productModel.materials[materialName].needsUpdate = true;
                }
              }
            );

        
          }
        });
      };

      suitDetails &&
        applyTextures(
          "Jacket",
          `https://raymond101.s3.ap-south-1.amazonaws.com/Suits/${suitDetails?.skuCode}/Albedo.jpg`
        );

      shirtDetails &&
        applyTextures(
          "Shirt",
          `https://raymond101.s3.ap-south-1.amazonaws.com/Shirts/${shirtDetails?.skuCode}/Albedo.jpg`
        );

      trouserDetails &&
        applyTextures(
          "Trouser",
          `https://raymond101.s3.ap-south-1.amazonaws.com/Trousers/${trouserDetails?.skuCode}/Albedo.jpg`
        );
    }
    setProductState(false);
  }, [productModel, suitDetails, shirtDetails, trouserDetails, styleDetails]);

  //changeing pose
  useEffect(() => {
    if (
      Object.keys(sourceModelState).length === 0 ||
      Object.keys(targetModelState).length === 0
    )
      return;

    if (sourceModelState.error || targetModelState.error) return;

    if (!sourceModel?.scene && !targetModel?.scene) return;

    setPoseState(true);
    console.log(sourceModel);

    const mixer1 = new THREE.AnimationMixer(sourceModel.scene);

    const action1 = mixer1.clipAction(sourceModel.animations[0]);

    action1.play();

    const mixer2 = new THREE.AnimationMixer(targetModel.scene);

    const action2 = mixer2.clipAction(action1?.getClip());

    action2.play();

    action2.clampWhenFinished = true; // Ensure the animation stops after one loop
    action2.repetitions = 1;

    function animate() {
      requestAnimationFrame(animate);
      // Update animation mixer
      mixer1.update(1);
      mixer2.update(1);

      setPoseState(false);
    }
    animate();
    SkeletonUtils.retarget(
      new THREE.SkeletonHelper(sourceModel.scene).bones,
      new THREE.SkeletonHelper(targetModel.scene).bones
    );
  }, [sourceModel, targetModel, poseType, shoesState]);

  return (
    <Center>
      {bgModel && <primitive object={bgModel.scene} />}

      {targetModel && <primitive object={targetModel.scene} />}
      {productModel && <primitive object={productModel.scene} />}
    </Center>
  );
};

export default FinalScene;
