import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
// import { productDetailsReducer } from './reducers/productReducers.js'
import {
  sourceModelsReducers,
  sourceModelReducer,
  avatarModelDetailsReducer,
  targetModelReducer,
  backgroundModelReducers,
  productModelReducer,
} from "./reducers/modelReducers.js";

const reducer = combineReducers({
  sourceModels: sourceModelsReducers,
  avatarModelDetails: avatarModelDetailsReducer,
  sourceModel: sourceModelReducer,
  targetModel: targetModelReducer,
  productModel: productModelReducer,
  backgroundModel: backgroundModelReducers,
});

const avatarDetailsFromStorage = localStorage.getItem("modelId")
  ? localStorage.getItem("modelId")
  : "plugin-default";

const avatarDetailsWeightFromStorage = localStorage.getItem("weightId")
  ? localStorage.getItem("weightId")
  : 80;

const initialState = {
  avatarModelDetails: {
    avatar: {
      modelId: avatarDetailsFromStorage,
      weightId: avatarDetailsWeightFromStorage,
    },
  },
  sourceModels: {
    sources: [
      {
        weight: 60,
        modelUrl:
          "https://raymond101.s3.ap-south-1.amazonaws.com/assets/60KG/60KG_SOURCE.glb",
      },
      {
        weight: 70,
        modelUrl:
          "https://raymond101.s3.ap-south-1.amazonaws.com/assets/70KG/70KG_SOURCE.glb",
      },
      {
        weight: 80,
        modelUrl:
          "https://raymond101.s3.ap-south-1.amazonaws.com/assets/80KG/80KG_SOURCE.glb",
      },
      {
        weight: 90,
        modelUrl:
          "https://raymond101.s3.ap-south-1.amazonaws.com/assets/90KG/90KG_SOURCE.glb",
      },
      {
        weight: 100,
        modelUrl:
          "https://raymond101.s3.ap-south-1.amazonaws.com/assets/100KG/100KG_SOURCE.glb",
      },
    ],
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
