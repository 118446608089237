import React from "react";
import { Icon } from "@iconify/react";
import FabricsCard from "./FabricsCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
const MobileMenu = ({
  styleDetails,
  activeCanvas,
  setCompareStyleDetails,
  setStyleDetails,
  fabricData,
  compareSuitDetails,
  showCompare,
  suitDetails,
  setCompareSuitDetails,
  setSuitDetails,
  shirtDetails,
  setShirtDetails,
  setCompareShirtDetails,
  trouserDetails,
  compareTrouserDetails,
  setTrouserDetails,
  setCompareTrouserDetails,
  compareStyleDetails,
}) => {
  return (
    <div
      className={`position-fixed  p-2 pb-4 d-lg-none`}
      style={{
        bottom: "0%",
        width: "100%",
        zIndex: "99",
        background: "#342F2F",
        minHeight: "20dvh",
        overflowY: "hidden",
      }}
    >
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-style-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-style"
            type="button"
            role="tab"
            aria-controls="nav-style"
            aria-selected="true"
          >
            Styles
          </button>
          <button
            className="nav-link "
            id="nav-jacket-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-jacket"
            type="button"
            role="tab"
            aria-controls="nav-jacket"
            aria-selected="true"
          >
            Jacket
          </button>
          <button
            className="nav-link "
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Shirts
          </button>
          <button
            className="nav-link "
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            Trousers
          </button>
        </div>
      </nav>
      <div className="tab-content  pt-2" id="nav-tabContent">
        <div
          className="tab-pane pb-1 fade show active"
          id="nav-style"
          role="tabpanel"
          aria-labelledby="nav-style-tab"
        >
          <div className="mt-2 ">
            <Splide
              style={{ zIndex: "99" }}
              options={{
                rewind: true,
                width: 800,
                drag: "free",
                gap: "1rem",
                arrows: false,
                pagination: false,
                perPage: 3,
                snap: true,
              }}
            >
              <SplideSlide>
                <div
                  className={`active-fabric rounded-3  align-items-center d-flex ${
                    styleDetails === "DB_CLOTH" && "bg-dark "
                  }`}
                  onClick={() => {
                    if (activeCanvas === 1) {
                      setCompareStyleDetails("DB_CLOTH");
                    } else {
                      setStyleDetails("DB_CLOTH");
                    }
                  }}
                >
                  <div
                    className=" rounded-3 mt-2 "
                    style={{
                      height: "3px",
                      position: "absolute",
                      top: "5%",
                      left: "5%",
                      background: " #9858FF",
                      translate: "-5% 0%",
                      width: styleDetails === "DB_CLOTH" ? "10%" : "0%",
                      zIndex: "10",
                      transition: "0.3s ease",
                    }}
                  ></div>

                  <div
                    className=" rounded-3 mt-2 "
                    style={{
                      height: "3px",
                      position: "absolute",
                      top: "5%",
                      left: styleDetails === compareStyleDetails ? "20%" : "5%",
                      background: "#FFC107",
                      translate: "-10% 0%",
                      width:
                        showCompare && compareStyleDetails === "DB_CLOTH"
                          ? "10%"
                          : "0%",
                      zIndex: "10",
                      transition: "0.3s ease",
                    }}
                  ></div>

                  <p
                    style={{ fontSize: "0.9em", fontWeight: "500" }}
                    className="text-light ms-2"
                  >
                    Double Breasted
                  </p>

                  <img
                    src="./assets/DB_PREVIEW.png"
                    style={{ width: "50%" }}
                    className="img-fluid   "
                    alt=""
                  />
                </div>
              </SplideSlide>{" "}
              <SplideSlide>
                <div
                  className={`active-fabric rounded-3 align-items-center d-flex ${
                    styleDetails === "SB_AMERICAN_CLOTH" && "bg-dark  "
                  }`}
                  onClick={() => {
                    if (activeCanvas === 1) {
                      setCompareStyleDetails("SB_AMERICAN_CLOTH");
                    } else {
                      setStyleDetails("SB_AMERICAN_CLOTH");
                    }
                  }}
                >
                  <div
                    className=" rounded-3 mt-2 "
                    style={{
                      height: "3px",
                      position: "absolute",
                      top: "5%",
                      left: "5%",
                      background: " #9858FF",
                      translate: "-5% 0%",
                      width:
                        styleDetails === "SB_AMERICAN_CLOTH" ? "10%" : "0%",
                      zIndex: "10",
                      transition: "0.3s ease",
                    }}
                  ></div>

                  <div
                    className=" rounded-3 mt-2 "
                    style={{
                      height: "3px",
                      position: "absolute",
                      top: "5%",
                      left: styleDetails === compareStyleDetails ? "20%" : "5%",
                      background: "#FFC107",
                      translate: "-10% 0%",
                      width:
                        showCompare &&
                        compareStyleDetails === "SB_AMERICAN_CLOTH"
                          ? "10%"
                          : "0%",
                      zIndex: "10",
                      transition: "0.3s ease",
                    }}
                  ></div>
                  <p
                    style={{ fontSize: "0.9em", fontWeight: "500" }}
                    className="text-light ms-2"
                  >
                    Single Breasted American
                  </p>
                  <img
                    src="./assets/AMERICAN_PREVIEW.png"
                    style={{ width: "50%" }}
                    className="img-fluid   "
                    alt=""
                  />
                </div>
              </SplideSlide>{" "}
              <SplideSlide>
                <div
                  className={`active-fabric rounded-3 align-items-center d-flex ${
                    styleDetails === "SB_ITALIAN_CLOTH" && "bg-dark "
                  }`}
                  onClick={() => {
                    if (activeCanvas === 1) {
                      setCompareStyleDetails("SB_ITALIAN_CLOTH");
                    } else {
                      setStyleDetails("SB_ITALIAN_CLOTH");
                    }
                  }}
                >
                  <div
                    className=" rounded-3 mt-2 "
                    style={{
                      height: "3px",
                      position: "absolute",
                      top: "5%",
                      left: "5%",
                      background: " #9858FF",
                      translate: "-5% 0%",
                      width: styleDetails === "SB_ITALIAN_CLOTH" ? "10%" : "0%",
                      zIndex: "10",
                      transition: "0.3s ease",
                    }}
                  ></div>

                  <div
                    className=" rounded-3 mt-2 "
                    style={{
                      height: "3px",
                      position: "absolute",
                      top: "5%",
                      left: styleDetails === compareStyleDetails ? "20%" : "5%",
                      background: "#FFC107",
                      translate: "-10% 0%",
                      width:
                        showCompare &&
                        compareStyleDetails === "SB_ITALIAN_CLOTH"
                          ? "10%"
                          : "0%",
                      zIndex: "10",
                      transition: "0.3s ease",
                    }}
                  ></div>
                  <p
                    style={{ fontSize: "0.9em", fontWeight: "500" }}
                    className="text-light ms-2"
                  >
                    Single Breasted Italian
                  </p>
                  <img
                    src="./assets/Italian_PREVIEW.png"
                    style={{ width: "50%" }}
                    className="img-fluid   "
                    alt=""
                  />
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div
          className="tab-pane "
          id="nav-jacket"
          role="tabpanel"
          aria-labelledby="nav-jacket-tab"
        >
          <div className="mt-2">
            <Splide
              style={{ zIndex: "99" }}
              options={{
                rewind: true,
                width: 800,
                drag: "free",
                gap: "1rem",
                arrows: false,
                pagination: false,
                perPage: 6,
                snap: true,
              }}
            >
              {fabricData &&
                fabricData.map((item) => {
                  return (
                    item.category.includes("Suits") && (
                      <SplideSlide>
                        <FabricsCard
                          item={item}
                          showCompare={showCompare}
                          activeFabricTab={"Suits"}
                          activeCanvas={activeCanvas}
                          details={suitDetails}
                          compareDetails={compareSuitDetails}
                          setFabric={setSuitDetails}
                          setCompareFabric={setCompareSuitDetails}
                        />
                      </SplideSlide>
                    )
                  );
                })}
            </Splide>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div className="mt-2">
            <Splide
              style={{ zIndex: "99" }}
              options={{
                rewind: true,
                width: 800,
                drag: "free",
                gap: "1rem",
                arrows: false,
                pagination: false,
                perPage: 6,
                snap: true,
              }}
            >
              {fabricData &&
                fabricData.map((item) => {
                  return (
                    item.category.includes("Shirts") && (
                      <SplideSlide>
                        <FabricsCard
                          item={item}
                          showCompare={showCompare}
                          activeFabricTab={"Shirts"}
                          activeCanvas={activeCanvas}
                          details={shirtDetails}
                          compareDetails={compareSuitDetails}
                          setFabric={setShirtDetails}
                          setCompareFabric={setCompareShirtDetails}
                        />
                      </SplideSlide>
                    )
                  );
                })}
            </Splide>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div className="mt-2">
            <Splide
              style={{ zIndex: "99" }}
              options={{
                rewind: true,
                width: 800,
                drag: "free",
                gap: "1rem",
                arrows: false,
                pagination: false,
                perPage: 6,
                snap: true,
              }}
            >
              {fabricData &&
                fabricData.map((item) => {
                  return (
                    item.category.includes("Trousers") && (
                      <SplideSlide>
                        <FabricsCard
                          item={item}
                          showCompare={showCompare}
                          activeFabricTab={"Trousers"}
                          activeCanvas={activeCanvas}
                          details={trouserDetails}
                          compareDetails={compareTrouserDetails}
                          setFabric={setTrouserDetails}
                          setCompareFabric={setCompareTrouserDetails}
                        />
                      </SplideSlide>
                    )
                  );
                })}
            </Splide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
