import AppScreen from "./screens/AppScreen";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import GenrateAvatarScreen from './screens/GenrateAvatarScreen'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={AppScreen} index />
        <Route path="/genrate" Component={GenrateAvatarScreen} index />
      </Routes>
    </Router>
  );
}

export default App;
